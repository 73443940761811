<template>
  <div class="content_box">
    <!-- 顶部图片 -->
    <div class="head_img_box">
      <el-image :src="headImg">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
      </el-image>
            <!-- 期间限定 -->
      <a style="display: block;" target="_blank" href="https://maidocoin.com/entrance/?lang=ko">
        <el-image :src="qjxdImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a>

      <!-- 积分 -->
      <!-- <a id="stepImgId" style="display: block;" target="_blank" href="https://tripellet.com/tcapp-download-b/tc">
        <el-image :src="stepImg">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </a> -->
      <!-- 设施一览表 -->
      <div style="background-color: #f3d2c3;">
        <el-image :src="modelcourse">
        <div slot="placeholder" class="image-slot">
          <img :src="loadImg" class="loadImg" alt="" />
        </div>
        </el-image>
        <el-image :src="modelcourse2">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
        <el-image :src="placeholder_title">
          <div slot="placeholder" class="image-slot">
            <img :src="loadImg" class="loadImg" alt="" />
          </div>
        </el-image>
      </div>
      <!-- 使用方法 -->
      <!-- <div class="usage_box">
        <div class="title">関西エリアで人気のチケットです。大阪、神戸、京都など、魅力的な観光スポットと人気施設が対象し、有効期間で対象施設の中からお好きな３つ施設又は6つ施設を選んで楽しめます。また、電子チケットQRコードを提示すると簡単に入場ができます。</div>
        <div class="syff">
          <div class="syff_left">
            <p></p>
            <p></p>
          </div>
          <div class="txt">使用方法</div>
          <div class="syff_right">
            <p></p>
            <p></p>
          </div>
        </div>
        <div class="neiron">
            ●各施設のご利用は1回のみです。同施設を2回ご利用できません。<br>
            ●パスの使用期間は購入日から<span>90日</span>以内です。<br>
            EX：<span>7/1</span>ご購入の場合、<span>7/1~9/28</span>使用可能です。<br>
            ●パスの有効期間：1つ目の施設をご入場から<span>7日間</span>以内です。<br>
            EX：<span>7/1</span>から利用開始の場合、有効期間は<span>7/7</span>までです。<br>
            ●利用可能施設数：参画施設の中から、任意の3施設又は6施設<br>
            ●注意：同オーダーに複枚数をご購入の場合、中の一枚を利用すると、全て同じ<span>7日間</span>有効になります。<br>
            ●パスの有効期間7日間は購入日から<span>90日間</span>以内に限ります。<br>
            EX：<span>7/1~9/28</span>使用可能のパスを<span>9/27</span>から利用開始された場合、
            有効期間は<span>9/27~9/28</span>です。<br>
            ●未使用分を払い戻すことはできません。<br>
            ●各施設の営業時間・定休日・利用に関する注意事項は下記リンク、
            または施設公式サイトでご確認ください。
        </div>
      </div> -->
      <!-- 景点介绍 -->
      <div class="main_box" v-for="(item,i) in dataList" :key="i">
        <!-- 图片 -->
        <div class="img_box">
          <el-image :src="item.img" lazy>
            <div slot="placeholder" class="image-slot">
              <img :src="loadImg" class="loadImg" alt="" />
            </div>
          </el-image>
        </div>

        <div class="xiao_btn_img" v-if="item.iconImgBr">
          <a v-if="item.iconImgBr1" target="_blank" :href="item.iconVideoHref"><img :src="xiao_btn_img1" alt=""></a>
          <a v-if="item.iconImgBr2" href="javascript:;" style="display: none;"><img :src="xiao_btn_img2" alt=""></a>
          <a v-if="item.iconImgBr3" href="javascript:;"><img :src="xiao_btn_img3" alt=""></a>
        </div>
        <!-- 文字 -->
        <div class="text_box">
          <div class="title" v-for="(title,i) in item.title" :key="i" v-html="title"></div>
          <hr>
          <div class="message_box">
            <div class="message_item" v-for="msg in item.message" :key="msg.msgId">
              <div style="margin-bottom:5px">{{msg.title}}</div>
              <div v-if="msg.title!=='注意事項'">
                <div v-for="contentItem in msg.content" :key="contentItem.cid">
                  <span v-for="(cItem,i) in contentItem.text" :key="i">
                    <span v-show="msg.title==='引き換え場所' && !contentItem.noColor">{{cItem}}<br></span>
                    <a class="dltAbq" :style="[{'borderBottom': !contentItem.noColor ? '1px solid' : ''},{color: !contentItem.noColor ? '#219DCC' : ''},{'wordWrap': 'break-word'}]"   target="_blank" :href="cItem" v-show="msg.title==='公式サイト' && !contentItem.noColor">{{cItem}}<br></a>
                    <p v-show="contentItem.noColor" v-html="cItem"></p>
                  </span>
                </div>
              </div>
              <!-- 注意事項特殊处理 -->
              <!-- :style判断是否要数字前缀 -->
              <div :style="{marginLeft:msg.isDecimal ? '16.5px' : '0px'}" v-else>
                <ul :style="{listStyleType:msg.isDecimal ? 'decimal' : 'none'}">
                  <li v-for="contentItem in msg.content" :key="contentItem.cid">
                    <span v-for="(cItem,i) in contentItem.text" :key="i" v-html="cItem"><br></span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 景点介绍 end -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      headImg: require('@/assets/images/hfkansai/ja/havefun_kansai_jp.png'),
      qjxdImg: require('@/assets/images/hfkansai/ja/qjxd_banner_jp.png'),
      // stepImg: require('@/assets/images/hfkansai/ja/4step_jp.png'),
      modelcourse: require('@/assets/images/hfkansai/ja/havefun_modelcourse_1_jp.jpg'),
      modelcourse2: require('@/assets/images/hfkansai/ja/havefun_modelcourse_2_jp.jpg'),
      placeholder_title: require('@/assets/images/hfkansai/ja/placeholder_title_JP.png'),
      loadImg: require('@/assets/images/hfkansai/loading.png'),
      xiao_btn_img1: require('@/assets/images/hfkansai/ja/play_JP.png'),
      xiao_btn_img2: require('@/assets/images/hfkansai/jifen.png'),
      xiao_btn_img3: require('@/assets/images/hfkansai/zh/qjxd2_SC.png'),
      dataList: [
        // {
        //   id: 1,
        //   img: require('@/assets/images/hfkansai/zh/グループ 264.png'),
        //   title: ['あべのハルカス300'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '引き換え場所',
        //       content: [{ cid: 1, text: ['大阪市阿倍野区阿倍野筋1-1-43'] }, { cid: 2, text: ['(HARUKAS300展望台16階)'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '公式サイト',
        //       content: [{ cid: 1, text: ['https://www.abenoharukas-300.jp/index.html'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事項',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['一度ご退場いただくと、再入場することができません。'] },
        //         { cid: 2, text: ['16階で電子チケットを提示して、ご入場ください。'] }
        //       ]
        //     }]
        // },
        {
          id: 2,
          img: require('@/assets/images/hfkansai/kintetsu_tc2.jpg'),
          title: ['近鉄百貨オリジナルセット引き換えクーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['大阪府大阪市阿倍野区阿倍野筋1-1-43','ウイング館3.5階　Foreign Customer’s  Salon'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://abenoharukas.d-kintetsu.co.jp/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['近鉄百貨店オリジナルセット引換券をご堪能いただけます。','(オリジナル今治タオル＆クリアファイル1枚セット)'] },
                { cid: 2, text: ['セット商品の返品・交換はお受け出来ません。'] },
                { cid: 3, text: ['あべのハルカス近鉄本店のみご利用いただけます。'] },
                { cid: 4, text: ['画像はイメージです。実際の商品とはデザイン·仕様が一部異なる場合がございます。'] }
              ]
            }
          ]
        },
        {
          id: 3,
          img: require('@/assets/images/hfkansai/zh/グループ 263.png'),
          title: ['大阪ワンダーループバス'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['大阪府大阪市中央区道頓堀1丁目16川沿いB1', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://wonderloop.jp/ja/wonder-loop/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['走行中はシートベルトをお締めください。', 'また、危険です ので座席の移動はお止め下さい。'] },
                { cid: 2, text: ['行事等（交通規制や誘導）がある場合は運休または路線を', '変更する場合があります。'] }
              ]
            }
          ]
        },
        {
          id: 4,
          img: require('@/assets/images/hfkansai/zh/グループ 262.png'),
          iconImgBr: true,
          iconVideoHref:'https://youtu.be/oXH-_2qjNlU',
          title: ['大阪ワンダークルーズ'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['大阪府大阪市中央区道頓堀1丁目16川沿いB1', '(Dotonbori Riverside Grill Wonder)'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://wondercruise.jp/doton/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['運行スケジュールは公式サイトにてご確認ください。'] },
                { cid: 2, text: ['強風や雨など、悪天候時は運行中止となる場合があります。'] },
                { cid: 3, text: ['満席の場合はご乗船をお断りすることがございます。'] },
                { cid: 4, text: ['毎週月～水曜日はご予約の方のみの運航です。'] }
              ]
            }
          ]
        },
        {
          id: 5,
          img: require('@/assets/images/hfkansai/zh/グループ 261.png'),
          title: ['大阪グルメウォーク チケット(2枚)'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['●大阪府大阪市中央区道頓堀1丁目16川沿いB1', '(Dotonbori Riverside Grill Wonder)'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['http://gourmet-walk.com/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['引換場所で提示すると、2枚を受け取れます。'] },
                { cid: 2, text: ['大阪グルメウォークチケットはご自分で外さず、必ず繁がったままのチケットをお店側にお渡し下さい。'] },
                { cid: 3, text: ['グルメウォークメニューの代替は出来ません。'] },
                { cid: 4, text: ['余ったチケットの換金にはお応え出来ません。'] },
                { cid: 5, text: ['引換場所の営業時間は事前に公式サイトをご確認ください。'] },
                ]
            }
          ]
        },
        {
          id: 6,
          img: require('@/assets/images/hfkansai/298_tc.png'),
          iconImgBr: true,
          iconVideoHref:'https://youtu.be/TGv70R5L37k',
          title: ['298焼肉 1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '●298なんば店(当面営業中止)',
                '大阪府大阪市中央区千日前1-7-9　千日前スバル座東あじびる南4F',
                '●298心斎橋店',
                '大阪府大阪市中央区心斎橋筋1-2-8',
                '●298梅田3号店',
                '大阪府大阪市北区堂山町5-8',
                '(泉の広場上がる北側)',
                '●298三宮店<span style="color:#FBB03B;">(神戸)</span>',
                '神戸市中央区北長狭通1-4-2　あじびる三宮B1',
                '●298福島店',
                '大阪府大阪市福島区福島6-1-39'],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['298なんば店 '],noColor:true },
                { cid: 2, text: ['http://www.ajibil.com/shop/298N.html'] },
                { cid: 3, text: ['298心斎橋店'],noColor:true },
                { cid: 4, text: ['https://298shinsaibashi.owst.jp/'] },
                { cid: 5, text: ['298梅田3号店'],noColor:true },
                { cid: 6, text: ['https://ajibiru-kashin-umeda.owst.jp/'] },
                { cid: 7, text: [`298三宮店<span style="color:#FBB03B;">(神戸) </span>`],noColor:true },
                { cid: 8, text: ['http://www.ajibil.com/shop/298S.html'] },
                { cid: 9, text: [`298福島店`],noColor:true },
                { cid: 10, text: ['https://r.gnavi.co.jp/jm0fdd5d0000/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['298三宮店は神戸にございます。'] },
                { cid: 4, text: ['下記5店舗のいずれかを1つ店舗のみご利用ください。','298なんば店、298心斎橋店、298梅田3号店、298三宮店、298福島店'] },
                { cid: 5, text: ['満席になって利用できない可能があります。予めご了承ください。'] }
                ]
            }
          ]
        },
        // 
        {
          id: 7,
          img: require('@/assets/images/hfkansai/nonotori_CH.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/wg6_QwDLejA',
          title: ['野乃鳥 1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '●野乃鳥なんば堂',
                '大阪市中央区難波３-7-19GEMSなんば8F',
                '●KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(神户)</span>',
                '神戸市中央区北長狭通１-1-１　EKIZO神戸三宮',
                '●野乃鳥 梅味堂',
                '大阪市北区中崎西3-3-15　JR高架下34',],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['野乃鳥なんば堂'],noColor:true },
                { cid: 2, text: ['https://nonotory.jp/stores/nambado/'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 野乃鳥<span style="color:#FBB03B;">(神户)</span>'],noColor:true },
                { cid: 4, text: ['https://nonotory.jp/stores/kobe-yakitori-stand/'] },
                { cid: 5, text: ['野乃鳥 梅味堂'],noColor:true },
                { cid: 6, text: ['https://nonotory.jp/stores/umemido/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['KOBE YAKITORI STAND 野乃鳥は神戸にございます。'] },
                { cid: 4, text: ['下記3店舗のいずれかを1つ店舗のみご利用ください。','野乃鳥なんば堂、KOBE YAKITORI STAND 野乃鳥、野乃鳥 梅味堂。'] },
                { cid: 5, text: ['入店時にお通し代がかかります。'] },
                { cid: 6, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 44,
          img: require('@/assets/images/hfkansai/zh_tw/heniuunou_TC.png'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['和牛焼肉うのう　1000円クーポン','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['別館：大阪府大阪市西成区玉出中2-15-22'] },
                { cid: 2, text: ['都島本通店：大阪府大阪市都島区都島本通3-27-7'] },
                { cid: 3, text: ['福島本店：大阪府大阪市福島区福島2-7-7'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['別館：サイト無し'],noColor:true },
                { cid: 2, text: ['都島本通店：'],noColor:true },
                { cid: 3, text: ['https://www.hotpepper.jp/strJ000716500/'] },
                { cid: 4, text: ['https://instagram.com/unou_gram?igshid=YzAwZjE1ZTI0Zg=='] },
                { cid: 5, text: ['福島本店：'],noColor:true },
                { cid: 6, text: ['https://unoufukushima.owst.jp/'] },
                { cid: 7, text: ['https://www.instagram.com/unou_7319/?hl=ja'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                { cid: 4, text: ['1店舗につきのご利用は1回限りです。','<br>例: 別館は利用済みですが、都島本通店は利用可能'] },
                { cid: 5, text: ['混雑時は90分制です。'] },
              ]
            }
          ]
        },
        {
          id: 45,
          img: require('@/assets/images/hfkansai/zh_tw/Nanjamonja_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['なんじゃもんじゃ　1000円クーポン','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['大阪府大阪市中央区東心斎橋1-13-19 NTビル2F'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://r.gnavi.co.jp/k342800/https://www.instagram.com/nanjamonja_shinsaibashi/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンで大阪名物お好み焼きをお楽しみ頂けます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
              ]
            }
          ]
        },
        {
          id: 46,
          img: require('@/assets/images/hfkansai/zh_tw/95jiaoshi_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          iconImgBr2: true,
          iconImgBr3: true,
          title: ['大阪三線教室 1時間体験 (事前予約必須)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['大阪府大阪市生野区巽中4-4-5'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://500en-sanshin-school.jimdosite.com/'] },
                { cid: 2, text: ['(予約サイト)'],noColor:true },
                { cid: 3, text: ['https://airrsv.net/sansin/calendar'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: 一時間体験料'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
              ]
            }
          ]
        },
        {
          id: 47,
          img: require('@/assets/images/hfkansai/zh_tw/96jas_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['宗教法人 久安寺 座禅、写経、写仏体験 (事前予約必須)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['大阪府池田市伏尾町697'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://kyuanji.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['商品内容: 座禅、写経、写仏体験。3つのうちどれか。拝観料300円含む。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
              ]
            }
          ]
        },
        {
          id: 48,
          img: require('@/assets/images/hfkansai/zh_tw/97rwgj_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['染め屋貴久  染め体験1000円クーポン(事前予約必須)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['大阪府池田市栄町6-17'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://someyakikyu.com/'] },
                { cid: 1, text: ['(予約サイト)'],noColor:true },
                { cid: 1, text: ['https://kikyu.urkt.in/ja/direct/offices/65/courses'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。'] },
              ]
            }
          ]
        },
        {
          id: 49,
          img: require('@/assets/images/hfkansai/zh_tw/98bbdc_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['日本殺陣道協会 本部道場 体験1000円クーポン(事前予約必須)','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['大阪府大阪市西区北堀江1丁目1-27 (3F)'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://nihontatedokyokai.com/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 4, text: ['事前予約が必要です。'] },
              ]
            }
          ]
        },
        {
          id: 50,
          img: require('@/assets/images/hfkansai/zh_tw/Kawaii_TC.jpg'),
          iconImgBr: true,
          // iconImgBr1: true,
          // iconImgBr2: true,
          iconImgBr3: true,
          title: ['カワイイオオサカ KIMONO SHOP　着物購入/着物体験1000円クーポン','<span>(期間限定:2023.12.20 ~ 2024.02.29)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['大阪府大阪市中央区宗右衛門町1-9'] },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://kawaii-osaka.jp/'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。(着物購入/着物体験)'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 4, text: ['予約状況などでスムーズにご対応できない場合があります。HPからネット予約をされることを推奨します。'] },
              ]
            }
          ]
        },
        // 
        {
          id: 17,
          img: require('@/assets/images/hfkansai/zh/SantaMaria_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/FVCxk3PCAA8',
          title: ['サンタマリア デイクルーズ 乘船劵','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '〒552-0022 大阪府大阪市港区海岸通1丁目1-10',
                ],noColor:true }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://suijo-bus.osaka/language/santamaria/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['運行スケジュールは公式ウェブサイトにてご確認ください。'] },
                { cid: 2, text: ['強風や雨等、悪天候時は運行中止となる場合があ'] },
                { cid: 3, text: ['満席の場合はご乗船をお断りすることがございます。'] },
                ]
            }
          ]
        },
        // 
        {
          id: 18,
          img: require('@/assets/images/hfkansai/en/Paragliding_en.png'),
          title: ['パラグライダーソロ体験　1000円クーポン','<span>(2023年7月~8月体験不可)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['大阪府大阪市此花区北港緑地2-1-44',
                '(舞洲アーバンアウトドアベース)',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/paraglider/index.html'], },
                { cid: 2, text: ['予約サイト(日本語のみ): '],noColor:true },
                { cid: 3, text: ['https://osakamachipara.com/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['全予約制で営業いたします。ご利用の際は、事前予約が必要になります。事前にご予約の無い場合は、休校している場合もございます。'] },
                { cid: 2, text: ['完全予約制。ご予約はホームページより。','(ホームページ2月20日以降にオープン)'] },
                { cid: 3, text: ['持ち物は、運動出来る服装・運動靴　が必要です。'] },
                { cid: 4, text: ['そのほか、当日流れのタイムスケジュールや体験申込書をご参考ください。'] },
                { cid: 5, text: ['体験制限 (年齢:3歳以上/身長:190cm以下/体重:80kg以下)'] },
                ]
            }
          ]
        },
        // 
        {
          id: 19,
          img: require('@/assets/images/hfkansai/zh/SUPExperience_tc.png'),
          title: ['水上さんぽ　SUP体験　1000円クーポン','<span>2023.04.01~NEW</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒554-0042 大阪府大阪市此花区北港緑地１丁目２−25',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.west-east.jp/mishima-citysup.html'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['丸サップ/BIGサップは中学生以下のみでの体験はできません。'] },
                { cid: 2, text: ['自転車サップには身長制限があります。'] },
                { cid: 3, text: ['飲酒の方/妊娠中の方は体験できません。'] },
                { cid: 4, text: ['天候等により中止の場合もあります。'] },
                { cid: 5, text: ['濡れてもいい格好でお越しください。','(水しぶきや落船により濡れる可能性があります。)'] },
                ]
            }
          ]
        },
        {
          id: 20,
          img: require('@/assets/images/hfkansai/zh/terminal_tc.png'),
          title: ['大阪府レストランフロア「イチロクグルメ」お食事券1,300円分','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒530-0001 大阪市北区梅田3-1-3 JR大阪駅5F 時空の広場 「バール・デルソーレ」','<span style="color:red;">※「BAR del SOLE」7/18（火）～21（金）まで休業（飲食券の配布ができない）</span>'],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://osakastationcity.com/16gourmet/'], },
                { cid: 2, text: ['https://osakastationcity.com/pdf/ichiroku_inbound.pdf'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['対象店舗で使用できるお食事券1,000円分×1枚、300円分×1枚の合計2枚をお渡しします。'] },
                { cid: 2, text: [
                  'お食事券は、下記の店舗で金券としてご利用いただけます。',
                  '<br>・サウスゲートビル16階イチロクグルメ「宮崎しゃぶしゃぶ霧峰」「あ・らん」「大阪竹葉亭」「うを佐」「銀座ハゲ天」「洋麺屋五右衛門」「サロン卵と私」「サンマルク」「カフェ 英國屋」「肉の匠 永田屋」「弦」「八かく庵」「PIER 30 GRILL」「中国料理 ロドス」',
                  '<br>・サウスゲートビル1階「カフェ・ド・クレバー」',
                  '<br>・サウスゲートビル地下1階「サンマルコ」',
                  '<br>・時空の広場（大阪駅5階）「バール・デルソーレ」'
                  ] },
                { cid: 3, text: ['お食事券は、1回のお会計につき複数枚のご利用ができます。'] },
                { cid: 4, text: ['お食事券は、現金とのお引換え及びおつりのお渡しはできません。'] },
                { cid: 5, text: ['お食事券は、使用店舗にて回収いたします。'] },
                { cid: 6, text: ['お食事券は、大丸梅田店・ホテルグランヴィア大阪・ルクア大阪・エキマルシェ大阪・大阪ステーションシティシネマ等ではご利用いただけません。'] },
                { cid: 7, text: ['ご利用店舗は、変更となる場合がございます。'] },
                { cid: 8, text: ['営業時間は予告なく変更する場合があります。また、臨時休業・満席等の事情でご利用いただけなかった場合も払戻はありません。'] },
                { cid: 9, text: ['お食事券へ引き換えた後はキャンセル・払戻はできません。'] },
                { cid: 10, text: ['QRコードの提示ではご利用いただけません。必ずお食事券に引き換えてから各店舗をご利用ください。'] },
                { cid: 11, text: ['対象店舗以外でのご利用はできません。'] },
                ]
            }
          ]
        },
        {
          id: 21,
          img: require('@/assets/images/hfkansai/zh/meitian_tc.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/ezYPmNdS_no',
          title: ['梅田スカイビル 空中庭園展望台 入場券','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒531-6039 大阪市北区大淀中1-1-88　梅田スカイビル (39階チケットカウンター)',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.skybldg.co.jp/tw/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['なにわ淀川花火大会開催日（2023/8/5）、年末年始などの特別営業日は使用不可。'] },
                { cid: 2, text: ['車椅子のお客様はタワーイースト１階より高層用エレベーターをご利用ください。'] },
                { cid: 3, text: ['荒天時には屋上へご入場いただけない場合もありますのでご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 22,
          img: require('@/assets/images/hfkansai/zh/CHOICE_tc.png'),
          title: ['BAR CHOICE　1000円クーポン','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒542-0083  中央区東心斎橋1-8-24　米山ビル１F',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://choice-cola.com/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 23,
          img: require('@/assets/images/hfkansai/zh/Wonder_tc.png'),
          title: ['Dotonbori Grill & Bar Wonder 1000円クーポン','<span>(2023/5/15~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒542-0071 大阪市中央区道頓堀1-1-6 川沿いB1',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://wonderpub.jp/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                { cid: 5, text: ['アルコール飲料は販売します。20 歳以上の方のみ購入可能。'] },
                ]
            }
          ]
        },
        {
          id: 24,
          img: require('@/assets/images/hfkansai/zh/chisozanmai_tc.png'),
          title: ['大丸松坂屋百貨店 馳走三昧 大丸梅田店 1000円クーポン','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒530-8202 大阪市北区梅田3-1-1 大丸 大阪・梅田店14F',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.daimaru.co.jp/umedamise/restaurant/chisozanmai.html'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。（おひとり様5000円以上のご利用の場合に限ります。）'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 26,
          img: require('@/assets/images/hfkansai/zh/LUCUAosaka_tc.jpg'),
          title: ['大阪駅直結ショッピングセンター「LUCUA osaka」館内共通商品券　1,000円分','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['LUCUA 1100 2F インフォメーションカウンター','<span style="color:red;">※受付は2023年12月31日まで</span>',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.lucua.jp/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['含む内容：1000円分の共通クーポン1枚'] },
                { cid: 2, text: ['商品券の有効期限は2023年8月31日までに引き換えた場合は、2023年8月31日まで有効です。2023年9月1日以降に引き換えた場合は、2024年1月31日まで有効です。'] },
                { cid: 3, text: ['本券は現金への引き換えおよび他の商品券・切手・ハガキ等の商品の購入にはご利用いただけません'] },
                { cid: 4, text: ['おつりのお渡しはできません。'] },
                { cid: 5, text: ['LUCUA内一部店舗ではご利用ただけません。（利用不可店舗：LUCUA 5F「シープラ」、LUCUA 9F「フジモト眼科」、LUCUA 1100（イーレ） B1F「マクドナルド」）また、掲載している店舗写真についても2023年3月時点のものであり、店舗変更の可能性がございます。'] },
                { cid: 6, text: ['営業時間は予告なく変更する場合があります。また、臨時休業・満席等の事情でご利用いただけなかった場合も払戻はありません。'] },
                ]
            }
          ]
        },
        {
          id: 27,
          img: require('@/assets/images/hfkansai/zh/EKI_MARCHE_tc.jpg'),
          title: ['大阪駅構内ショッピングセンター「エキマルシェ大阪」館内共通クーポン 1000円分','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['エキマルシェ大阪内インフォメーションカウンター',
                '<span style="color:red;">※受付は2023年12月31日まで</span>',
                '<span style="color:red;">※電子チケットからクーポン券への引き換えは、予約日より90日有効となります。</span>',
                '<span style="color:red;">※休館日<5/9(火)、9/5(火)>については、ご利用・引換いただけません</span>',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://osaka.ekimaru.com/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['含む内容：500円分の共通クーポン2枚'] },
                { cid: 2, text: ['クーポン券の有効期限は引き換え日の翌月末です。(例:3/15日にクーポン券に引き換えた場合、クーポン券は4/30まで有効です。)'] },
                { cid: 3, text: ['本券は1回のお会計につき複数枚のご利用ができます。'] },
                { cid: 4, text: ['本券は現金とのお引換え及びおつりのお渡しはできません。'] },
                { cid: 5, text: ['営業時間は予告なく変更する場合があります。また、臨時休業・満席等の事情でご利用いただけなかった場合も払戻はありません。'] },
                { cid: 6, text: ['クーポン券へ引き換えた後はキャンセル・払戻はできません。'] },
                { cid: 7, text: ['QRコードの提示ではご利用いただけません。必ずクーポン券に引き換えてから各店舗をご利用ください。'] },
                { cid: 8, text: ['対象店舗以外でのご利用はできません。（エキマルシェ内のクーポン券対象外店舗：JTB、銀行ATM、DreamShop）'] },
                { cid: 9, text: ['上限は500名分、無くなり次第提供を終了します。'] },
                ]
            }
          ]
        },
        {
          id: 29,
          img: require('@/assets/images/hfkansai/zh/Yamachan_tc.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/dbJ6ER5g6GM',
          title: ['大阪梅田たこ焼マーケット やまちゃん HEPファイブ店　1000円クーポン','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒530-0017 大阪府大阪市北区角田町５−１５ HEPファイブ １階',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 30,
          img: require('@/assets/images/hfkansai/zh/HEP_NAVIO_tc.jpg'),
          title: ['大阪梅田たこ焼マーケット たこ焼十八番 HEPナビオ店　1000円クーポン','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒530-0017 大阪府大阪市北区角田町７－１０ HEPナビオ １階',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 31,
          img: require('@/assets/images/hfkansai/zh/Yashiki_tc.jpg'),
          title: ['大阪梅田たこ焼マーケット甲賀流 HEPナビオ店　1000円クーポン','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒530-0017 大阪府大阪市北区角田町７－１０ HEPナビオ １階',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 32,
          img: require('@/assets/images/hfkansai/zh/kukuru_HEP_FIVE_tc.jpg'),
          title: ['大阪梅田たこ焼マーケット くくる HEPファイブ店　1000円クーポン','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒530-0017 大阪府大阪市北区角田町５−１５ HEPファイブ １階',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 33,
          img: require('@/assets/images/hfkansai/zh/HEP_NAVIO_Umeda_tc.jpg'),
          title: ['大阪梅田たこ焼マーケット 会津屋 梅田HEP NAVIO店　1000円クーポン','<span>(2023/7/3~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒530-0017 大阪府大阪市北区角田町７－１０ HEPナビオ １階',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://takoyakimarket.com/index.html'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 34,
          img: require('@/assets/images/hfkansai/zh/bistecca_tc.png'),
          title: ['bistecca IL FORNO 1000円クーポン','<span>(2023/8/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒550-0015大阪府大阪市西区南堀江1－5－17 キャナルテラス堀江 西棟１F',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://bistecca-ilforno.jp/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 4, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
                ]
            }
          ]
        },
        {
          id: 35,
          img: require('@/assets/images/hfkansai/zh/MIO_TC.jpg'),
          title: ['天王寺MIO 館内共通券1500円','<span>(2023/9/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒543-0055　大阪市天王寺区悲田院町10-39　"天王寺MIO　本館" 2Fインフォメーションカウンター','※受付は2023年12月30日まで',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.tennoji-mio.co.jp/access'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['本券は、天王寺MIO本館・プラザ館両館にてご利用いただけます。'] },
                { cid: 2, text: ['一部の店舗ではご利用いただけません。ご利用の際に、各店係員におたずねください。'] },
                { cid: 3, text: ['差額は現金でお支払いください。おつりのお渡しはできません。'] },
                { cid: 4, text: ['本券は払戻できません。また、現金への引き換えもできません。'] },
                { cid: 5, text: ['本券の火災、盗難、紛失、滅失等に対し、その責任を負いません'] },
                { cid: 6, text: ['一部店舗は営業時間が異なります。また、営業時間は予告なく変更する場合があります。'] },
                { cid: 7, text: ['含む内容：500円分の共通クーポン3枚'] },
                ]
            }
          ]
        },
        {
          id: 41,
          img: require('@/assets/images/hfkansai/zh_tw/EDION_TC.jpg'),
          title: ['EDIONなんば観光満喫パック 1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['大阪市中央区難波3丁目2番18号　エディオンなんば本店8階'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://namba.edion.com/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: [
                  'パック含む内容：',
                  '<br>・忍者脱出ゲーム体験(1,000円相当)',
                  '<br>・荷物預かり1日無料(800円相当)',
                  '<br>・パウダールーム1時間利用(300円相当) ※最新家電やコスメなどが利用できます。',
                  '<br>・充電スポット30分利用(200円相当) ※iPhoneとアンドロイド両方とも利用可能です。'
                ] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 4, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 5, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
              ]
            }
          ]
        },
        {
          id: 42,
          img: require('@/assets/images/hfkansai/zh_tw/rentcar_tc.png'),
          title: ['駅レンタカー　2,000円分クーポン券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: [
                '（１）駅レンタカー　新大阪営業所',
                '大阪市淀川区西中島5-16-1　JR新大阪駅（南出口1階）',
                '（２）駅レンタカー　京都営業所',
                '京都市下京区東塩小路町940　JR京都駅（駅ビル駐車場1階）',
                '（３）駅レンタカー　新神戸営業所',
                '神戸市中央区加納町1-3-1　JR新神戸駅（１階コンコース））',
                '（４）駅レンタカー　姫路営業所',
                '兵庫県姫路市南駅前町125　JR姫路駅（中央改札を左へ、南出口出て左、駅前駐車場内）',
                ] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['（１）駅レンタカー　新大阪営業所'],noColor:true },
                { cid: 2, text: ['https://www.ekiren.com/eigyosyo?eigyou=270200'] },
                { cid: 3, text: ['（２）駅レンタカー　京都営業所'],noColor:true },
                { cid: 4, text: ['https://www.ekiren.com/eigyosyo?eigyou=260400'] },
                { cid: 5, text: ['（3 ）駅レンタカー　新神戸営業所'],noColor:true },
                { cid: 6, text: ['https://www.ekiren.com/eigyosyo?eigyou=290200'] },
                { cid: 7, text: ['（4 ）駅レンタカー　姫路営業所'],noColor:true },
                { cid: 8, text: ['https://www.ekiren.com/eigyosyo?eigyou=290800'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['2000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['当日利用限定・予約不可'] },
                { cid: 3, text: ['使用方法：対象店舗にてレンタカーをお申し込みの際に、楽しい関西パスQRコード・運転免許証・パスポート（日本国籍以外の方)をご提示ください。'] },
                { cid: 4, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 5, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 6, text: ['車種指定はできません。'] },
                { cid: 7, text: ['満車になって利用できない可能があります。予めご了承ください。'] },
                { cid: 8, text: ['外国の免許をお持ちの方が、日本で運転する際には有効な「国際運転免許証」もしくは「外国運転免許証+日本語翻訳」が必要です。必ずご来店する前に、ご確認しておいてください。'] },
                { cid: 9, text: ['ガソリンは満タン貸し、満タン返しです。返却時の現地精算も可能ですが、市場価格よりも割高になります。（ガソリンの走行距離での計算となります。）'] },
                { cid: 10, text: ['本商品ご利用に際しては、日本国内法およびレンタカー貸渡約款に準じます。'] },
              ]
            }
          ]
        },
        {
          id: 43,
          img: require('@/assets/images/hfkansai/zh_tw/mingShi_tc.png'),
          title: ['明石の鮨クーポン「技の逸品」2,000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['兵庫県明石市大明石町1-1-23　（あかし案内所）'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.yokoso-akashi.jp/coupon.html'] },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['含めるもの: クーポン券に金額(2000円)に対応した明石の鮨メニュー（1人前）'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['食材の入荷状況や季節により、メニューの一部が変更されることがあります。事前のお知らせはいたしかねますので、ご了承ください。'] },
              ]
            }
          ]
        },
        // {
        //   id: 36,
        //   img: require('@/assets/images/hfkansai/zh/ADVENTURE_WORLD_TC.jpg'),
        //   title: ['アドベンチャーワールド 1日入園券1000クーポン','<span>(2023/9/1~)</span>'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '引き換え場所',
        //       content: [
        //         { cid: 1, text: ['〒649-2201 和歌山県西牟婁郡白浜町堅田2399番地 (チケット売り場)',],noColor:true },
        //         ]
        //     },
        //     {
        //       msgId: 2,
        //       title: '公式サイト',
        //       content: [
        //         { cid: 1, text: ['https://www.aws-s.com/'], },
        //         ]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事項',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['1000円クーポンをご堪能いただけます。(入園券のみ)'] },
        //         { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
        //         { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
        //         ]
        //     }
        //   ]
        // },
        {
          id: 8,
          img: require('@/assets/images/hfkansai/zh/グループ 255.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/N-MxbCGVfIM',
          title: ['京都鉄道博物館 ','<span>(2023/8/18~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['京都府京都市下京区観喜寺町 (入館ゲート)'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://www.kyotorailwaymuseum.jp/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['当日限り再入館ができますので、再入館や運転シミュレータ抽選時には記念入館券をご提示ください。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
              ]
            }
          ]
        },
        // 
        {
          id: 9,
          img: require('@/assets/images/hfkansai/sake_EN.jpg'),
          title: ['Japanese SAKE Festival in KYOTO ','「Sake Spring 」2023夏祭り ちょい呑みチケット'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['〒606-8343 京都市左京区岡崎成勝寺町9番地の1','(京都市勧業館みやこめっせ 3Ｆ 第3展示場)'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://www.sakesp.com/2023summer'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['イベント開催期間: 2023/7/8~2023/7/9 '] },
                { cid: 2, text: ['テイスティングイベントです。20歳未満の方は参加不可。'] },
                { cid: 3, text: ['一度ご退場いただくと、再入場することができません。'] },
                { cid: 4, text: ['営業時間は事前に公式サイトをご確認ください。'] },
              ]
            }
          ]
        },
        {
          id: 37,
          img: require('@/assets/images/hfkansai/zh_tw/TOEI_TC.jpg'),
          title: ['京都東映太秦映画村入場券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['京都府京都市右京区太秦東蜂岡町１０'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://www.toei-eigamura.com/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 2, text: ['アトラクションには利用制限がありますので事前に公式サイトをご確認ください。'] },
              ]
            }
          ]
        },
        {
          id: 38,
          img: require('@/assets/images/hfkansai/zh_tw/pph_TC.jpg'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/nvht8Y_bbYk',
          title: ['びわ湖大津プリンスホテル館内共通券　1,500円分'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['滋賀県大津市におの浜4-7-7'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://www.princehotels.co.jp/otsu/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['館内共通券の期限は、発行より1週間です。有効期限の過ぎた館内共通券は無効です。'] },
                { cid: 2, text: ['本券は宿泊代にはご利用いただけません'] },
                { cid: 3, text: ['差額は現金でお支払いください。おつりのお渡しはできません。'] },
                { cid: 4, text: ['営業時間は予告なく変更する場合があります。また、臨時休業・満席等の事情でご利用いただけなかった場合も払戻はありません。'] },
              ]
            }
          ]
        },
        {
          id: 39,
          img: require('@/assets/images/hfkansai/zh_tw/PASSPORT_TC.jpg'),
          title: ['長浜おでかけパスポート（２日間）'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['長浜市北船町1-5（JR長浜駅構内）'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://kitabiwako.jp/spot/spot_8966'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['パスポートは利用開始日を含めて2日間（暦日）有効です。※48時間ではありません'] },
                { cid: 2, text: ['１施設１回の入場です。同じ施設に何度も入場することはできません。'] },
                { cid: 3, text: ['事前に施設や店舗の営業時間や休業日を確認のうえご利用ください。'] },
                { cid: 4, text: ['各施設特別展により追加料金が必要になる場合があります。'] },
              ]
            }
          ]
        },
        {
          id: 40,
          img: require('@/assets/images/hfkansai/zh_tw/yxt_TC.jpg'),
          title: ['一心堂　1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['奈良県奈良市上三条町3番地の9'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://issindo-nara.jp/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                { cid: 3, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
              ]
            }
          ]
        },
        {
          id: 28,
          img: require('@/assets/images/hfkansai/zh/Yoshitaya_tc.jpg'),
          title: ['吉高屋 カメ印美肌石鹸 引き換えクーポン','<span>(2023/6/13~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒651-1401　兵庫県神戸市北区有馬町259',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['https://www.yoshitakaya.com/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['画像はイメージです。実際の商品とはデザイン・仕様が一部異なる場合がございます。'] },
                { cid: 2, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                ]
            }
          ]
        },
        {
          id: 25,
          img: require('@/assets/images/hfkansai/zh/carbonated_tc.jpg'),
          title: ['元祖三津森本舗 炭酸煎餅30枚入り丸缶','<span>(2023/6/1~)</span>'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [
                { cid: 1, text: ['〒651-1401　兵庫県神戸市北区有馬町290-1',],noColor:true },
                ]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [
                { cid: 1, text: ['http://tansan.co.jp/'], },
                ]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['店舗は混雑する場合があります。その際は、一般のお客さまと同様にお待ちいただきますようお願いいたします。優先権等はございません。'] },
                { cid: 2, text: ['画像はイメージです。実際の商品とはデザイン・仕様が一部異なる場合がございます。'] },
                { cid: 3, text: ['営業時間は事前に公式サイトをご確認ください。'] },
                ]
            }
          ]
        },
        // {
        //   id: 6,
        //   img: require('@/assets/images/hfkansai/en/グループ 225.png'),
        //   title: ['Arashiyama Gourmet Coupon +', 'Randen 1 Day Free Ticket'],
        //   message: [
        //     {
        //       msgId: 1,
        //       title: '引き換え場所',
        //       content: [{ cid: 1, text: ['20-2 Sagatenryuji Tsukurimichicho,', 'Ukyo ku, Kyoto'] }]
        //     },
        //     {
        //       msgId: 2,
        //       title: '公式サイト',
        //       content: [{ cid: 1, text: ['https://www.keifuku.co.jp/'] }]
        //     },
        //     {
        //       msgId: 3,
        //       title: '注意事項',
        //       isDecimal: true, //是否启用ul的数字前缀
        //       content: [
        //         { cid: 1, text: ['Please check the train timetable on the', '公式サイト.'] },
        //         { cid: 2, text: ['Please check the 公式サイト for the stores', ' that are eligible for the food coupon.'] },
        //         { cid: 3, text: ['The remaining food coupons cannot be', 'redeemed for cash.'] },
        //       ]
        //     }
        //   ]
        // },
        {
          id: 10,
          img: require('@/assets/images/hfkansai/zh/グループ 257.png'),
          iconImgBr: true,
          iconImgBr1: true,
          iconVideoHref:'https://youtu.be/q101QK5r8Sc',
          title: ['神戸布引ハーブ園ロープウェイ入場券'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['兵庫県神戸市中央区北野町1-4-3', '(山麓駅４Fインフォメーション)'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://www.kobeherb.com/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['ナイター営業時（17:00以降）は、展望エリアのみ', '入園が可能です。'] },
                { cid: 2, text: ['無休。ロープウェイの年次点検のため冬季間約1カ月', 'の運休・休園を予定しています。'] },
                { cid: 3, text: ['強風・雷発生時には、運休・休園する場合があります。'] },
              ]
            }
          ]
        },
        {
          id: 11,
          img: require('@/assets/images/hfkansai/zh/グループ 258.png'),
          title: ['神戸コンチェルト 1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['神戸市中央区東川崎町1-6-1 神戸ハーバーランド umie1階', '(コンチェルト乗船場)'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://thekobecruise.com/concerto/'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['運行スケジュール公式サイトにてご確認ください。'] },
                { cid: 2, text: ['満席の場合はご乗船をお断りすることがございます。'] },
                { cid: 3, text: ['料金差額は現場でお支払い必要があります。'] },
              ]
            }
          ]
        },
        {
          id: 12,
          img: require('@/assets/images/hfkansai/zh/グループ 259.png'),
          title: ['モザイク大観覧車'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['神戸市中央区東川崎町1-6-1 umieモザイク'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://umie.jp/features/mosaickanransya'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: false, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['天候不良や点検整備等で臨時休業あり。'] },
              ]
            }
          ]
        },
        {
          id: 13,
          img: require('@/assets/images/hfkansai/zh/グループ 260.png'),
          title: ['観音屋 1000円クーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['神戸市中央区東川崎町1-6-1 umieモザイク1F'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/76'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポンをご堪能いただけます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
              ]
            }
          ]
        },
        {
          id: 14,
          img: require('@/assets/images/hfkansai/zh/グループ 248.png'),
          title: ['とんとんとんかつ 1000円クーポン/', 'セットメニュー'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['神戸市中央区東川崎町1-6-1 umieモザイク2F'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/93'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: true, //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['1000円クーポン/セットメニューをご堪能いただけます。'] },
                { cid: 2, text: ['料金差額分の場合、現場でお支払い必要があります。'] },
                { cid: 3, text: ['満席になって利用できない可能があります。予めご了承ください。'] },
              ]
            }
          ]
        },
        {
          id: 15,
          img: require('@/assets/images/hfkansai/zh/グループ 247.png'),
          title: ['フランツ スイーツクーポン'],
          message: [
            {
              msgId: 1,
              title: '引き換え場所',
              content: [{ cid: 1, text: ['神戸市中央区東川崎町1-6-1 umieモザイク2F'] }]
            },
            {
              msgId: 2,
              title: '公式サイト',
              content: [{ cid: 1, text: ['https://umie.jp/shops/detail/98'] }]
            },
            {
              msgId: 3,
              title: '注意事項',
              isDecimal: false, //是否启用ul的数字前缀 //是否启用ul的数字前缀
              content: [
                { cid: 1, text: ['3種類のスイーツからいずれか1つお選びください。'] },
              ]
            }
          ]
        },
      ]
    }
  },
  created () {
    // this.$router.push('/hfPage?id=HFKansai&l=jp')
    window.open('https://www.travelcontentsapp.com/have-fun/have-fun-in-kansai-1-week-free-pass/jp', '_self')
  }
}
</script>
<style lang="scss" scoped>
.xiao_btn_img{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  margin-top: 16px;
  a{
    height: 32px;
    width: 32%;
    // flex: 1;
    // text-align: center;
  }
  img{
    // flex: 1;
    // width: auto;
    width: 90%;
    max-width: 144px;
    height: auto;
  }
}
.content_box {
  font-family: "Genseki";
  position: relative;
  width: 100%;
  height: 100%;
   // 使用方法
  .usage_box {
    background-color: #FFFBA0;
    padding: 18px 20px;
    margin: 20px 15px;
    color: #1A1311;
    font-weight: 700;
    margin-top: 0;
    .title{
      font-size: 13px;
      line-height: 19px;
    }
    .syff {
      display: flex;
      justify-content: space-between;
      margin: 18px 0;
      .txt {
        font-size: 21px;
        margin: 0 8.5px;
      }

      .syff_left,
      .syff_right {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        p{
          display: inline-block;
          height: 1px;
          width: 100%;
          background-color: #707070;
          margin-bottom: 2px;
        }
      }
    }
    .neiron {
      line-height: 21px;
      font-size: 12px;
      span{
        color: #FA6460;
      }
    }
  }
  .main_box {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 26px;
    .img_box {
      width: 100%;
      box-shadow: 6px 0px 6px rgba(0, 0, 0, 0.16);
    }
    .text_box {
      position: relative;
      margin-top: 22px;
      padding: 0 10px;
      // width: 90%;
      // padding-left: 24px;
      // padding-right: 17px;
      .title {
        font-size: 17px;
        font-weight: bolder;
        color: #876b57;
        margin: 5px 0;
        /deep/span {
          color:#ff0000;
        }
      }
      .message_box {
        font-size: 13px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 5px;
        .message_item:not(:first-child) {
          margin-top: 22px;
        }
      }
    }
  }
}
.loadImg {
  width: 100%;
  height: auto;
}
hr {
  border: none;
  height: 1px;
  background-color: #707070;
}
a:link {
  color: #000;
}
a:visited {
  color: #000;
}
.dltAbq:hover {
  color: #000;
  border-bottom: 1px solid;
}
a:active {
  color: #000;
}
</style>